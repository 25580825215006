<template>
  <div class="page-content">
    <page-breadcrumb v-if="level" title="Stage Level" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="level">
        <form-generator :model="level" :schema="level_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="level">
      <h2 class="mb-2">Milestones In This Level</h2>
      <data-table-ssr id="milestone_list" ref="milestone_list" :limit-base="36" :pagination="false"
        :columns="milestone_fields" :get-list-fn="getMilestones"
        :create_schema="milestone_create_schema" :create-row-fn="createMilestone"
        :to-edit-fn="toEditPage"
        :delete-row-fn="deleteMilestone"
        :more_actions="[{ text: 'Create one above', icon: 'PlusCircleIcon', handler: createMilestoneAbove,}]"
      />
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import milestoneService from '../../milestone/service';

const level_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true }  },
      { label: 'Subtitle', field: 'subtitle', input_type: 'text'  },
      { label: 'Description', field: 'description', input_type: 'ui-component' },
      { label: 'Content', field: 'content', input_type: 'ui-component' },
    ]
  }
];

const milestone_create_schema = [
  {
    cols: 12,
    fields: [
      {
        label: 'Name', field: 'name', input_type: 'text',
        validate: { type: 'string', required: true }
      },
      { label: 'Subtitle', field: 'subtitle', input_type: 'text', }
    ],
  }
];
const milestone_fields = [
  { label: 'Display Order', field: 'display_order' },
  { label: 'Name', field: 'name' },
  { label: 'ID', field: 'id' },
  { label: 'Subtitle', field: 'subtitle' },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
];

export default {
  data(){
    return{
      level_form_schema,
      milestone_fields,
      milestone_create_schema,
      level: null,
      isUpdating: false,
      isDeleting: false,
      triggers: null,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Stage Levels',
          to: { name: 'stage_level-list' },
        },
        {
          text: `${this.level ? this.level.name : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getLevel();
  },
  methods:{
    // level
    async getLevel(){
      this.level = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.level),
      });
      this.getLevel();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "stage_level-list" });
    },
    // trigger
    async createMilestone(data) {
      let new_item = await milestoneService.create({ ...data, level_id: this.level._id });
      return new_item;
    },
    async createMilestoneAbove({ item }) {
      this.$refs.milestone_list.openCreatePopup({ display_order: item.display_order - 0.5 });
    },
    async getMilestones({ limit, page, query }){
      let data = await milestoneService.getList({
        query: JSON.stringify({ ...query, level_id: this.itemId }),
        limit, page
      });
      let list = [], total = 0;
      if(data) {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
    async deleteMilestone({ _id }) {
      await milestoneService.delete({ id: _id });
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'arena-milestone-detail', params: { milestone_id: _id } });
    },
    async edittrigger(row) {
      await milestoneService.update({ data: JSON.stringify(row) });
    },
  }
}
</script>
